import { useMutation } from '@apollo/client';
import { Box, Button, Group, InputBase, PasswordInput, TextInput, Title } from '@mantine/core';
import { isNotEmpty, useForm, UseFormReturnType } from '@mantine/form';
import React, { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { IMaskInput } from 'react-imask';

import { createUser } from '@/api/app/create_user';
import { AuthFormProps } from '@/components/app/auth/form_props';
import { AppProvider } from '@/components/shared/Provider';
import { PasswordRequirement } from '@/components/shared/password_requirement';

type FormState = {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  confirmPassword: string;
  phoneNumber?: string;
  referralCode?: string;
};

export const AuthSignupForm: React.FC<AuthFormProps> = ({ email, siteId, siteIdV2, orgId }) => {
  const form: UseFormReturnType<FormState, (values: FormState) => FormState> = useForm<FormState>({
    initialValues: {
      email: email,
      firstName: '',
      lastName: '',
      password: '',
      confirmPassword: '',
      phoneNumber: '',
      referralCode: '',
    },

    transformValues: (values) => ({
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      password: values.password,
      confirmPassword: values.confirmPassword,
      phoneNumber: values.phoneNumber?.replace(/\D/g, ''),
      referralCode: values.referralCode,
    }),

    validate: {
      firstName: isNotEmpty('Field is required'),
      lastName: isNotEmpty('Field is required'),
      confirmPassword: (value) => {
        if (value === '') {
          return 'Field is required';
        }
        if (value !== form.values.password) {
          return 'Passwords are not the same';
        }
      },

      password: (value) => {
        if (value === '') {
          return 'Field is required';
        }

        if (
          value.length < 12 ||
          !value.match('(.*[a-z].*)') ||
          !value.match('(.*[A-Z].*)') ||
          !value.match('(.*[0-9].*)') ||
          !value.match('(.*[^a-zA-Z0-9].*)')
        ) {
          return ' ';
        }
      },
      phoneNumber: (value) => value != '' && value?.length !== 14 && 'Phone number is incorrect',
    },
  });

  const [uuid, setUuid] = useState('');
  const [action, setAction] = useState('');
  const [token, setToken] = useState('');
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const inputSubmit = useRef<HTMLInputElement>(null);
  const [error, setError] = useState('');
  const [isRecaptchaV2, setIsRecaptchaV2] = useState(false);

  useEffect(() => {
    if (action) {
      inputSubmit.current?.click();
    }
  }, [action]);

  const [authenticate, { loading }] = useMutation(createUser);

  const [isShowReferralCodeInput, setIsShowReferralCodeInput] = useState(false);
  const handleSubmit = form.onSubmit((values: FormState) => {
    if (loading) {
      return;
    }
    authenticate({
      variables: {
        email: values.email,
        password: values.password,
        firstName: values.firstName,
        lastName: values.lastName,
        phoneNumber: values.phoneNumber,
        recaptchaToken: recaptchaToken,
        referrals: values.referralCode !== '' ? { code: values.referralCode || '' } : undefined,
        isRecaptchaV2: isRecaptchaV2,
      },
    })
      .then((data) => {
        setAction(data?.data?.data?.omniauthCallback ?? '');
        setUuid(data?.data?.data?.user?.uuid ?? '');
        setToken(data?.data?.data?.sessionToken ?? '');
      })
      .catch((e) => {
        if (e.networkError?.extensions?.code === 400 && !isRecaptchaV2) {
          setIsRecaptchaV2(true);
        } else {
          setError(e.networkError?.extensions?.details?.message || e.message);
        }
        setRecaptchaToken('');
      });
  });

  const requirements = [
    { re: /[0-9]/, label: 'Includes number' },
    { re: /[a-z]/, label: 'Includes lowercase letter' },
    { re: /[A-Z]/, label: 'Includes uppercase letter' },
    { re: /[$&+,:;=?@#|'<>.^*()%!-]/, label: 'Includes special symbol' },
  ];

  const checks = requirements.map((requirement) => (
    <PasswordRequirement
      key={requirement.label}
      label={requirement.label}
      meets={requirement.re.test(form.values.password)}
    />
  ));

  return (
    <AppProvider>
      <GoogleReCaptchaProvider reCaptchaKey={siteId || ''}>
        <Title tt="uppercase" fw="300" >Sign up</Title>
        <Group justify="center">
          <form style={{ whiteSpace: 'pre-line', width: '15rem' }} onSubmit={handleSubmit}>
            <TextInput
              mt="md"
              size="lg"
              label="Email"
              withAsterisk
              disabled
              {...form.getInputProps('email')}
            />
            <TextInput
              mt="md"
              size="lg"
              label="First name"
              withAsterisk
              {...form.getInputProps('firstName')}
            />
            <TextInput
              mt="md"
              size="lg"
              label="Last name"
              withAsterisk
              {...form.getInputProps('lastName')}
            />
            <InputBase
              mt="md"
              size="lg"
              label="Phone number"
              component={IMaskInput}
              mask="(000) 000-0000"
              {...form.getInputProps('phoneNumber')}
            />
            <PasswordInput
              my="md"
              size="lg"
              label="Password"
              withAsterisk
              {...form.getInputProps('password')}
            />
            <Box>
              <PasswordRequirement
                label="Includes at least 12 characters"
                meets={form.values.password.length > 11}
              />
              {checks}
            </Box>

            <PasswordInput
              mt="md"
              size="lg"
              label="Confirm password"
              withAsterisk
              {...form.getInputProps('confirmPassword')}
            />
            <Group justify="center" mt="md">
              <Button
                variant="transparent"
                onClick={() => setIsShowReferralCodeInput(!isShowReferralCodeInput)}
              >
                Enter referral code
              </Button>
            </Group>
            {isShowReferralCodeInput && (
              <TextInput size="lg" label="Referral code" {...form.getInputProps('referralCode')} />
            )}

            <Group justify="center" mt="md">
              <Button color="#7ABEE9" size="lg" disabled={!recaptchaToken} type="submit" loading={loading}>
                Sign up
              </Button>
            </Group>
          </form>
          {isRecaptchaV2 && (
            <ReCAPTCHA
              sitekey={siteIdV2 || ''}
              onChange={(token) => {
                setRecaptchaToken(token || '');
              }}
            />
          )}
        </Group>
        {!isRecaptchaV2 && !recaptchaToken && (
          <GoogleReCaptcha
            onVerify={(token) => {
              setRecaptchaToken(token);
            }}
          />
        )}
        {error.length > 0 && <p className="register-validation-message">{error}</p>}
        <form method="get" action={action} style={{ display: 'none' }}>
          <input type="hidden" name="uuid" value={uuid} />
          <input type="hidden" name="email" value={email} />
          <input type="hidden" name="token" value={token} />
          <input type="hidden" name="org_id" value={orgId} />
          <input type="submit" ref={inputSubmit} />
        </form>
      </GoogleReCaptchaProvider>
    </AppProvider>
  );
};
