import { graphql } from '@/api/gql';

export const createUser = graphql(`
  mutation createUser(
    $firstName: String!
    $lastName: String!
    $email: EmailAddress!
    $password: Password!
    $phoneNumber: String
    $referrals: ReferralAttributes
    $recaptchaToken: String!
    $isRecaptchaV2: Boolean!
  ) {
    data: createUser(
      input: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        password: $password
        recaptchaToken: $recaptchaToken
        phoneNumber: $phoneNumber
        referrals: $referrals
        omniauthRequest: true
        isRecaptchaV2: $isRecaptchaV2
      }
    ) {
      omniauthCallback
      sessionToken
      user {
        uuid
        email
      }
      error {
        message
        code
        details {
          message
          codeAsString
        }
      }
    }
  }
`);
