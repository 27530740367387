import React from 'react';

import AuthEmailForm from '@/components/app/auth/email_form';
import { AuthFormProps } from '@/components/app/auth/form_props';
import AuthPasswordForm from '@/components/app/auth/password_form';
import CateringRegistrationForm from '@/components/app/auth/registration_form';
import { AuthSignupForm } from '@/components/app/auth/signup_form';
import { AppProvider } from '@/components/shared/Provider';
import { createContainer } from '@/helpers/container_helper';

const Auth: React.FC<AuthFormProps> = (props) => {
  const { form } = props;

  return (
    //TODO move to separate entrypoints
    <AppProvider>
      {form === 'email' && <AuthEmailForm {...props} />}
      {form === 'password' && <AuthPasswordForm {...props} />}
      {form === 'signup' && <AuthSignupForm {...props} />}
      {form === 'register' && <CateringRegistrationForm {...props} />}
    </AppProvider>
  );
};

createContainer('auth-form', Auth);
