import React from 'react';
import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { IMaskInput } from 'react-imask';

import HiddenForm from '@/components/shared/mantine/HiddenForm';

import { AuthFormProps } from './form_props';
import { buttonClassNames } from './helpers';
import useRegistrationForm from './useRegistrationForm';

const CateringRegistrationForm: React.FC<AuthFormProps> = ({
  email,
  isMobile,
  isCatering,
  user,
  account,
  siteId,
  orgId,
}) => {
  const {
    form,
    handleRecaptchaToken,
    handleSubmit: onSubmit,
    fields,
    errors,
    action,
    loading,
  } = useRegistrationForm({ email, account, user, orgId });

  return (
    <GoogleReCaptchaProvider reCaptchaKey={siteId || ''}>
      <form id="auth-registration-form" className="new_account" onSubmit={onSubmit}>
        <div className="row-fluid">
          <div className="span5">
            <label className="control-label" htmlFor="firstName">
              First Name:
            </label>
            <div className="controls">
              <input
                placeholder="John"
                required={true}
                type="text"
                name="firstName"
                tabIndex={1}
                autoFocus
                {...form.getInputProps('firstName')}
              />
            </div>
            <label className="control-label" htmlFor="email">
              Email:
            </label>
            <div className="controls">
              <input
                placeholder="john@example.com"
                required={true}
                type="email"
                name="account_role[user_attributes][email]"
                value={form.values.email ?? ''}
                disabled
              />
            </div>
            {!user && (
              <>
                <label className="control-label" htmlFor="password">
                  Password:
                </label>
                <div className="controls">
                  <input
                    required={true}
                    pattern="^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,}$"
                    title="Password must be at least 8 characters long, include an uppercase letter, a lowercase letter, a number, and a symbol."
                    type="password"
                    name="password"
                    tabIndex={4}
                    {...form.getInputProps('password')}
                  />
                </div>
              </>
            )}
          </div>
          <div className="span5">
            <label className="control-label" htmlFor="lastName">
              Last Name:
            </label>
            <div className="controls">
              <input
                placeholder="Doe"
                required={true}
                type="text"
                name="lastName"
                tabIndex={2}
                {...form.getInputProps('lastName')}
              />
            </div>
            <label className="control-label" htmlFor="phoneNumber">
              Phone:
            </label>
            <div className="controls">
              <IMaskInput
                id="account_role_user_attributes_phone_number"
                placeholder="(312) 555-5555"
                mask="(000) 000-0000"
                required={true}
                type="tel"
                name="phoneNumber"
                tabIndex={3}
                value={form.values.phoneNumber ?? ''}
                onAccept={(value) => form.setFieldValue('phoneNumber', value)}
              />
            </div>
            {!user && (
              <>
                <label className="control-label" htmlFor="passwordConfirmation">
                  Confirm Password:
                </label>
                <div className="controls">
                  <input
                    required={true}
                    pattern="^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,}$"
                    title="Password must be at least 8 characters long, include an uppercase letter, a lowercase letter, a number, and a symbol."
                    type="password"
                    name="passwordConfirmation"
                    tabIndex={5}
                    {...form.getInputProps('passwordConfirmation')}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <br />
        <div className="row-fluid">
          <h3>Organization</h3>
          <label className="control-label" htmlFor="accountName">
            Organization Name:
          </label>
          <div className="controls">
            <input
              placeholder="ACME Inc."
              required={true}
              type="text"
              name="accountName"
              {...form.getInputProps('accountName')}
            />
          </div>
        </div>
        <br />
        <div className="row-fluid">
          <h3>Delivery Address</h3>
          <label className="control-label" htmlFor="address1">
            Address 1:
          </label>
          <div className="controls">
            <input
              placeholder="17 Cherry Tree Lane"
              required={true}
              type="text"
              name="address1"
              {...form.getInputProps('address1')}
            />
          </div>
          <label className="control-label" htmlFor="locationName">
            Delivery Drop Off Point:
          </label>
          <div className="controls">
            <input
              placeholder="Suite 25, Front Desk"
              required={true}
              type="text"
              name="locationName"
              className="input-xlarge"
              {...form.getInputProps('locationName')}
            />
          </div>
          <label className="control-label" htmlFor="city">
            City:
          </label>
          <div className="controls">
            <input
              placeholder="Chicago"
              required={true}
              type="text"
              name="city"
              {...form.getInputProps('city')}
            />
          </div>
          <div className="row-fluid">
            <div className="span2">
              <div className="controls">
                <label className="control-label" htmlFor="state">
                  State:
                </label>
                <input
                  placeholder="IL"
                  maxLength={2}
                  required={true}
                  type="text"
                  name="state"
                  className="input-mini"
                  {...form.getInputProps('state')}
                />
              </div>
            </div>
            <div className="span3" style={{ marginLeft: '-28px' }}>
              <div className="controls">
                <label className="control-label" htmlFor="zipCode">
                  Zip Code:
                </label>
                <input
                  placeholder="60654"
                  size={5}
                  maxLength={5}
                  required={true}
                  type="number"
                  name="zipCode"
                  className="input-mini number-only"
                  {...form.getInputProps('zipCode')}
                />
              </div>
            </div>
          </div>
        </div>
        <br />
        {errors.length ? (
          <ul className="text text-error">
            {errors.map((error, index) => (
              <li key={index}>{error}</li>
            ))}
          </ul>
        ) : null}
        <div className="row-fluid">
          <button
            type="submit"
            className={buttonClassNames(isMobile, isCatering)}
            disabled={loading}
          >
            {loading ? 'Please wait...' : 'Submit'}
          </button>
        </div>
      </form>
      {form.values.recaptchaToken ? null : <GoogleReCaptcha onVerify={handleRecaptchaToken} />}
      {action ? <HiddenForm method="get" action={action} fields={fields} /> : null}
    </GoogleReCaptchaProvider>
  );
};

export default CateringRegistrationForm;
