import { ApolloError, useLazyQuery } from '@apollo/client';
import { isEmail } from '@mantine/form';
import React, { useMemo, useState } from 'react';

import { getAuthProvider } from '@/api/app/get_auth_provider';
import HiddenForm from '@/components/shared/mantine/HiddenForm';

import { AuthFormProps } from './form_props';
import { buttonClassNames, inputClassNames, wrapperClassNames } from './helpers';

const AuthEmailForm: React.FC<AuthFormProps> = ({
  form,
  token,
  isMobile,
  isCatering,
  isSignup,
  shouldAcceptTerms,
}) => {
  const [email, setEmail] = useState('');
  const [action, setAction] = useState('');
  const [error, setError] = useState('');
  const [accepted, setAccepted] = useState(false);
  const [loading, setLoading] = useState(false);

  const [authProvider] = useLazyQuery(getAuthProvider, {
    onError: (e?: ApolloError) => {
      setError(e?.message ?? '');
      setLoading(false);
    },
    onCompleted: () => setError(''),
  });

  const incomplete = () =>
    loading || action !== '' || email === '' || (shouldAcceptTerms && !accepted);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!email) {
      setError(`Please enter your ${isSignup ? 'registration' : 'login'} email`);
      return;
    } else if (isEmail()(email)) {
      setError('Please provide a valid email');
      return;
    }
    setLoading(true);
    const { data } = await authProvider({ variables: { email: email } });
    setAction(data?.data?.providerUrl ?? '');
  };

  const onComplete = () => {
    setAction('');
    setError('');
    setLoading(false);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value.trim());
    setError('');
  };

  const fields = {
    login_hint: email,
    authenticity_token: token,
    sign_up: `${isSignup}`,
    terms_of_service: `${accepted}`,
  };

  return (
    <div id="auth-email-form" className="row-fluid">
      <div className={wrapperClassNames(isMobile, isCatering)}>
        <form className="form-elements" onSubmit={handleSubmit}>
          <div className="row-fluid">
            <h4 className="align-left">Email</h4>
            <input
              name="email"
              type="text"
              required
              aria-required="true"
              placeholder={`Enter your ${isSignup ? 'registration' : 'login'} email`}
              value={email}
              onChange={onChange}
              className={`${inputClassNames(isMobile, isCatering)} ${error != '' ? 'error' : ''}`}
              autoFocus
              autoComplete="username"
            />
            {error.length > 0 && <p className="register-validation-message">{error}</p>}
          </div>
          {isSignup && !isCatering && (
            <div style={{ marginTop: '0.5em' }}>
              By joining you agree to Fooda&#39;s{' '}
              <a href="https://www.fooda.com/terms-of-use">Terms</a> and{' '}
              <a href="https://www.fooda.com/privacy-policy">Privacy policy</a>
            </div>
          )}
          {shouldAcceptTerms && (
            <div style={{ marginBottom: '10px' }}>
              <input
                type="checkbox"
                name="terms_of_service"
                className="fooda-checkbox"
                required={true}
                style={{ marginLeft: 0 }}
                checked={accepted}
                onClick={() => setAccepted(!accepted)}
              />
              <label htmlFor="terms_of_service">
                <span>I accept</span>{' '}
                <a href="https://www.fooda.com/terms-of-use" target="_blank" rel="noreferrer">
                  Fooda&lsquo;s Terms of Service
                </a>
              </label>
            </div>
          )}
          <div className="row-fluid">
            <button
              type="submit"
              className={buttonClassNames(isMobile, isCatering)}
              disabled={incomplete()}
            >
              {action !== '' ? 'Please wait...' : 'Continue'}
            </button>
          </div>
        </form>
      </div>
      {action ? (
        <HiddenForm method="post" action={action} fields={fields} onComplete={onComplete} />
      ) : null}
    </div>
  );
};

export default AuthEmailForm;
