import { graphql } from '@/api/gql'

export const createCateringUserAccount = graphql(`
  mutation createCateringUserAccount($input: CreateCateringUserAccount!) {
    data: createCateringUserAccount(input: $input) {
      destination
      result {
        userId
        email
        firstName
        lastName
        phoneNumber
        accountId
        accountName
        locationName
        address1
        city
        state
        zipCode
      }
      errors
      omniauthCallback
      sessionToken
      uuid
      error {
        message
      }
    }
  }
`)
