import { useMutation } from '@apollo/client';
import { useForm, UseFormReturnType } from '@mantine/form';
import { useState } from 'react';

import { createCateringUserAccount } from '@/api/app/create_catering_user_account';
import { CreateCateringUserAccount } from '@/api/gql/graphql';

import { AccountData, UserData } from './form_props';
import { flash } from './helpers';

type FormValues = CreateCateringUserAccount;

type UseRegistrationFormArgs = {
  email: string;
  user?: UserData;
  account?: AccountData;
  orgId?: string;
};

type UseRegistrationForm = (props: UseRegistrationFormArgs) => {
  form: UseFormReturnType<FormValues>;
  handleRecaptchaToken: (token: string) => void;
  handleSubmit: () => void;
  fields: Record<string, string>;
  errors: string[];
  action: string;
  loading: boolean;
};

const useRegistrationForm: UseRegistrationForm = ({ email, user, account, orgId }) => {
  const [errors, setErrors] = useState<string[]>([]);
  const [fields, setFields] = useState<Record<string, string>>({});
  const [action, setAction] = useState('');
  const form = useForm<FormValues>({
    initialValues: {
      email,
      firstName: user?.firstName ?? '',
      lastName: user?.lastName ?? '',
      phoneNumber: user?.phoneNumber ?? '',
      password: '',
      passwordConfirmation: '',
      accountName: account?.accountName ?? '',
      locationName: account?.locationName ?? '',
      address1: account?.address1 ?? '',
      city: account?.city ?? '',
      state: account?.state ?? '',
      zipCode: account?.zipCode ?? '',
      recaptchaToken: '',
    },
  });
  const [signup, { loading }] = useMutation(createCateringUserAccount);

  const validatePassword = () => {
    if (user?.id) {
      return true;
    }

    return form.values.password && form.values.password === form.values.passwordConfirmation;
  };

  const handleRecaptchaToken = (token: string) => form.setFieldValue('recaptchaToken', token);

  const handleSubmit = form.onSubmit(
    async ({ email: _, password, passwordConfirmation, ...values }) => {
      if (loading) {
        return;
      }
      if (!validatePassword()) {
        flash('Please ensure that the password & confirm password entered are the same', 'error');
        return;
      }

      const input = {
        ...values,
        ...(user?.id ? { userId: user.id } : { email, password, passwordConfirmation }),
        ...(account?.id ? { accountId: account.id } : {}),
      };

      handleRecaptchaToken('');

      try {
        const { data } = await signup({ variables: { input } });
        if (data?.data?.errors?.length) {
          flash(data.data.errors.join('<br />'), 'error');
          setErrors(data.data.errors);
          return;
        }

        if (user?.id && data?.data?.destination) {
          // Auth0 flow - user created, redirect to final destination
          window.location.href = data?.data?.destination;
          return;
        }

        if (!user?.id && !data?.data?.omniauthCallback && data?.data?.destination) {
          // Unsupported zip-code; no user; lead created; redirect to final page
          window.location.href = data?.data?.destination;
          return;
        }

        if (data?.data?.result !== null) {
          flash('Account Created', 'success');
        }

        setAction(data?.data?.omniauthCallback ?? '');
        setFields({
          uuid: data?.data?.uuid ?? '',
          email,
          token: data?.data?.sessionToken ?? '',
          redirect_to: data?.data?.destination ?? '',
          org_id: orgId ?? '',
        });
      } catch (error) {
        if (error instanceof Error) {
          flash(error.message, 'error');
          setErrors([error.message]);
        }
      }
    }
  );

  return {
    form,
    handleRecaptchaToken,
    handleSubmit,
    fields,
    errors,
    action,
    loading,
  };
};

export default useRegistrationForm;
