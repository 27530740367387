import React from 'react';
import { Group, Text } from '@mantine/core';
import { IconCheck, IconX } from '@tabler/icons-react';

type PasswordRequirementsProps = {
  meets: boolean;
  label: string;
};

const IconState: React.FC<{ meets: boolean }> = ({ meets }) =>
  meets ? <IconCheck size="1rem" /> : <IconX size="1rem" />;
export const PasswordRequirement: React.FC<PasswordRequirementsProps> = ({ meets, label }) => {
  return (
    <Group c={meets ? 'teal' : 'gray'}>
      <IconState meets={meets} />
      <Text size="sm">{label}</Text>
    </Group>
  );
};
