export const wrapperClassNames = (isMobile: boolean, isCatering: boolean) => (
  isCatering ? '' :
    isMobile ? '' :
      'span6 offset3'
)

export const inputClassNames = (isMobile: boolean, isCatering: boolean) => (
  isCatering ? '' :
    isMobile ? 'span12' :
      'span12'
)

export const buttonClassNames = (isMobile: boolean, isCatering: boolean) => (
  isCatering ? 'btn btn-info fooda-colors' :
    isMobile ? 'span12' :
      'span12'
)

export const flash = (message: string, type: string) => {
  window.jQuery.bootstrapGrowl(message, { type });
};
