import { useMutation } from '@apollo/client';
import React, { useEffect, useRef, useState } from 'react';

import { createSession } from '@/api/app/create_session';

import { AuthFormProps } from './form_props';
import { buttonClassNames, inputClassNames, wrapperClassNames } from './helpers';

const AuthPasswordForm: React.FC<AuthFormProps> = ({ email, isMobile, isCatering, resetUrl }) => {
  const [password, setPassword] = useState('');
  const [uuid, setUuid] = useState('');
  const [action, setAction] = useState('');
  const [token, setToken] = useState('');
  const [error, setError] = useState('');
  const inputSubmit = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (action) {
      inputSubmit.current?.click();
    }
  }, [action]);

  const [authenticate, { loading }] = useMutation(createSession, {
    onCompleted: () => setError(''),
  });

  const onClick = async () => {
    if (!password) {
      setError('Please enter your password');
      return;
    }

    authenticate({ variables: { email, password } })
      .then((data) => {
        setAction(data?.data?.data?.session?.omniauthCallback ?? '');
        setUuid(data?.data?.data?.session?.user?.uuid ?? '');
        setToken(data?.data?.data?.session?.token ?? '');
      })
      .catch((error) => {
        if ('response' in error.networkError && error.networkError.response.status === 429) {
          setError('Too many requests');
        } else {
          setError('Wrong email or password');
        }
      });
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    setError('');
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onClick();
    }
  };

  return (
    <div id="auth-password-form" className="row-fluid">
      <div className={wrapperClassNames(isMobile, isCatering)}>
        <div className="form-elements">
          <div className="row-fluid">
            <h4 className="align-left">Email</h4>
            <input
              name="email"
              type="text"
              required
              aria-required="true"
              value={email}
              disabled={true}
              className={inputClassNames(isMobile, isCatering)}
            />
            <h4 className="align-left">Password</h4>
            <input
              name="password"
              type="password"
              required
              aria-required="true"
              placeholder="Enter your login password"
              value={password}
              onChange={onChange}
              onKeyDown={onKeyDown}
              className={`${inputClassNames(isMobile, isCatering)} ${error != '' ? 'error' : ''}`}
              autoFocus
              autoComplete="current-password"
            />
            {error.length > 0 && <p className="register-validation-message">{error}</p>}
          </div>
          <div className="row-fluid">
            <button
              onClick={onClick}
              type="button"
              className={buttonClassNames(isMobile, isCatering)}
              disabled={loading || action !== ''}
            >
              {loading || action !== '' ? 'Please wait...' : 'Log In'}
            </button>
          </div>
          {resetUrl ? (
            <div className="row-fluid" style={{ marginTop: 20 }}>
              <div className={isCatering ? '' : 'pull-right'}>
                <a href={resetUrl}>Forgot Password?</a>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <form method="get" action={action} style={{ display: 'none' }}>
        <input type="hidden" name="uuid" value={uuid} />
        <input type="hidden" name="email" value={email} />
        <input type="hidden" name="token" value={token} />
        <input type="submit" ref={inputSubmit} />
      </form>
    </div>
  );
};

export default AuthPasswordForm;
